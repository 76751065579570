import "babel-polyfill";

import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import "@/style/base.less";
import "@/style/global.less";

import "element-plus/theme-chalk/display.css";

import Vuei18n from "@/i18n/i18n";
import { createPinia } from "pinia";

const app = createApp(App);

app.use(router).use(ElementPlus).use(Vuei18n).use(createPinia()).mount("#app");
