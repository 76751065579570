// 最新资讯的数据
export const prefix = "http://139.159.218.193:51810/images/";
export var newsdata = [
  {
    id: 1,
    title: "智臻寰宇赢得客户招标项目",
    publish_time: "2023-08-24",
    info: `<p>经过坚持不懈的努力，2023年8月24日，智臻寰宇高科技（深圳）有限公司赢得国内某大型电力企业的智能设备数字化研发服务招标项目。这是我司进一步深化开拓智能电网市场，助力客户成功的新机会、新起点。</p>
          <p>我司将竭尽全力，配合客户，认真完成研发项目，按时按质交付产品，力争为客户创造更大的价值。</p>
          `,
    imgSrc: [],
    preViewImg: prefix + "b4f69fe1-1e56-499e-a109-c6d0eee08e10-03-xx.png",
  },
  {
    id: 2,
    title: "第一个计算机软件著作权的获得",
    publish_time: "2022-04-29",
    info: `<p>2022年4月，我司收到了中华人民共和国国家版权局颁发的计算机软件著作权等级证书（证书号：软著登字第9456697号）。</p>
      <p>星空世界后台管理系统（简称：星空世界）是智臻寰宇高科技（深圳）有限公司于2021年2月至8月间，历时半年研发的针对物联网的通用的云端后台管理系统。星空世界是一个功能全面、设计精美、技术先进的通用物联网后台管理系统。该系统主要实现终端（例如：各类传感器、设备、机器等）、边缘端和云端互联，实现对采集数据的实时感知、定位、追溯、预警、统计和大数据分析，以及云端对终端的配置、命令下发和集总控制。根据不同的行业客户需求，可以将该通用系统定制化开发使用，以广泛应用于多个行业。在此基础之上，可加入机器学习和人工智能算法，实现智慧物联网。</p><p>星空世界包括：前端、后端和数据库。前端基于Vue.js框架，用HTML、JavaScript和CSS编程语言写成。后端利用Python写成。数据库利用开源的关系型数据库和Redis。星空世界的前端后端和数据库均以Docker方式打包部署于公司。</p>
      <p>未来，我司将继续星空世界的迭代研发，可加入机器学习和人工智能算法，对大数据进行挖掘和分析，进一步实现数据的预测、诊断、决策，最终实现智慧物联网。</p>
      `,

    imgSrc: [prefix + "9523fe93-41e7-4021-a088-95f2bf4e061b-03-xx.jpg"],
    preViewImg: prefix + "9523fe93-41e7-4021-a088-95f2bf4e061b-03-xx.jpg",
  },
  {
    id: 3,
    title: "智臻寰宇赢得客户招标项目",
    publish_time: "2022-03-10",
    info: "<p>经过艰苦的投标环节，2022年3月9日，智臻寰宇高科技（深圳）有限公司赢得了国内某大型电力企业的智能设备研发服务招标项目。这是我司综合实力和坚忍不拔的精神的一次集中体现。这也是我司迈入智能电网市场的机会。我司将竭尽全力，认真完成研发项目，为客户赋能，希望为客户创造更大的价值。</p>",
    imgSrc: [],
    preViewImg: prefix + "5bffe320-2e9e-4a81-952c-c104ad581f00-03-xx.jpg",
  },
  {
    id: 4,
    title: "参展第二十三届中国国际高新技术成果交易会",
    publish_time: "2021-12-30",
    info: `<p>今年的疫情防控形势非常严重，高交会推迟到了12月底，但是最终召开。</p>
      <p>2021年12月27日-29日，智臻寰宇高科技（深圳）有限公司非常荣幸参展第二十三届中国国际高新技术成果交易会。地址:深圳国际会展中心宝安新馆，11号展馆，我司展会号:11D28-07）。</p><p>第一次来到深圳宝安新馆参展，被宝安新馆巨大的现代化的展馆和完备的设施所吸引。
      本届高交会，我司参展产品是智慧物联网全栈解决方案，其中包括:</p><p>（1）星云系列边缘计算终端，支持多种无线通信方式、传感器和安全加密计算。</p><p>（2）星空物联云端管理系统（含前、后端，数据库）</p><p>（3）自然语言处理:OCR语音输出等。</p>
      <p>由于疫情的原因，本届高交会的人流量少了很多，但是我们还是和不少专业观众深入沟通，详细讨论了智慧物联网未来的发展和各种应用场景。</p>`,
    imgSrc: [
      prefix + "57b011c5-0118-4407-bc84-6cb9d4e45095-03-xx.jpg",

      prefix + "80016330-59c3-4f07-b292-61661a8b6995-03-xx.jpg",
    ],
    preViewImg: prefix + "57b011c5-0118-4407-bc84-6cb9d4e45095-03-xx.jpg",
  },
  {
    id: 5,
    title: "第一个客户的项目圆满交付",
    publish_time: "2021-12-15",
    info: `<p>智臻寰宇高科技（深圳）有限公司于2020年7月至2021年5月为国内某顶级环保家具企业开发数据分析平台，共四期，每一期都按时按质交付。智臻寰宇团队用杰出的技术、严谨的作风和高度负责任的精神，赢得了客户的好评。</p>
      <p>客户IT总监评价道：“智臻寰宇团队在项目实施过程中，深入我们的具体业务，能够充分理解我们的需求，项目文档及过程控制清晰。项目进度管控严格，交付质量非常高，确实保证项目的顺利交付，达到项目预期目标。项目交付后，运行已超过半年，产品运行稳定，性能满足我司需求。</p><p>智臻寰宇团队在整个开发过程和售后，均能提供优质的技术服务。“
      感谢客户的赠言，我司将继续努力，专注研发，更上一个台阶。</p>`,
    imgSrc: [],
    preViewImg: prefix + "8275396b-9ccd-4be0-a1b5-c4f29c8a7419-03-xx.jpg",
  },
  {
    id: 6,
    title: "参展第二十二届中国国际高新技术成果交易会",
    publish_time: "2020-11-16",
    info: `<p>分享高端智慧，点燃思想火花。作为中国最高级别的高新技术展览会，一年一度的深圳高交会来了。</p>
      <p>2020年11月11日-15日，智臻寰宇高科技（深圳）有限公司非常荣幸参展第二十二届中国国际高新技术成果交易会。展区地址：深圳福田区会展中心，我司展位号：10D22。</p>
      <p>在本届高交会上，我司展出了星云零号边缘计算终端。5天的展会，每天大约有三、四十位观众访问我司展位，讨论交流。让更多人了解我司，同时我们感受到了智慧物联网未来在各行各业发展的巨大潜力和机会。本次展会收获满满，圆满成功。</p>`,
    imgSrc: [prefix + "6fd84e76-7362-4d8c-8f42-99195051cfda-03-xx.jpg"],
    preViewImg: prefix + "6fd84e76-7362-4d8c-8f42-99195051cfda-03-xx.jpg",
  },
];

export var newsdata_en = [
  {
    id: 1,
    title: "Zhizhenhuanyu wins client bidding",
    publish_time: "August 24,2023",
    info: `<p>After unremitting efforts, on August 24th, 2023, Zhizhenhuanyu won the bidding project of digital R&D service for intelligent equipment of a large domestic electric power enterprise. This is a new opportunity and a new starting point for our company to further deepen the development of the smart grid market and help customers succeed.</p>
          <p>We will do our best to cooperate with our customers, complete the R&D projects seriously, deliver the products on time and in good quality, and strive to create greater value for our customers.</p>
          `,
    imgSrc: [],
    preViewImg: prefix + "b4f69fe1-1e56-499e-a109-c6d0eee08een-03-xx.png",
  },
  {
    id: 2,
    title: "Acquisition of the first computer software copyright",
    publish_time: "April 29,2022",
    info: `<p>In April 2022, we received a computer software copyright grade certificate (Certificate No. 9456697) issued by the National Copyright Administration of the People's Republic of China. </p
    <p>Star World Management System (abbreviated as: Star World) is a universal cloud-based management system for the IoT, developed by Zhizhenhuanyu High-Tech (Shenzhen) Co. Star World is a fully functional, beautifully designed and technologically advanced universal IoT backend management system. The system interconnects terminals (e.g. sensors, devices, machines, etc.), the edge and the cloud, enabling real-time sensing, location, traceability, early warning, statistics and big data analysis of collected data, as well as configuration, command issuance and centralised control of terminals from the cloud. The generic system can be customised and developed for use in a wide range of industries according to the needs of different industry customers. On top of this, machine learning and artificial intelligence algorithms can be added to achieve a smart IoT. </p><p>Star World includes: front-end, back-end and database. The front end is based on the Vue.js framework, written in the HTML, JavaScript and CSS programming languages. The back-end is written using Python. The front-end, back-end and database are packaged in Docker and deployed in the company. </p>
    <p>In the future, we will continue the iterative research and development of Star World, which can add machine learning and artificial intelligence algorithms to mine and analyse big data, further realise data prediction, diagnosis, decision making and eventually realise intelligent IoT. </p>
      `,
    imgSrc: [prefix + "9523fe93-41e7-4021-a088-95f2bf4e061b-03-xx.jpg"],
    preViewImg: prefix + "9523fe93-41e7-4021-a088-95f2bf4e061b-03-xx.jpg",
  },
  {
    id: 3,
    title: "We wins client bidding!!",
    publish_time: "March 10,2022",
    info: "<p>After a tough bidding process, on March 9, 2022, Zhizhenhuanyu High-Tech (Shenzhen) Co., Ltd. won the bidding for the R&D service of intelligent equipment for a large domestic power company. This is a concentrated expression of our comprehensive strength and perseverance. This is also an opportunity for us to enter the smart grid market. We will do our best to complete the R&D project carefully and empower our customers, hoping to create greater value for them. </p>",
    imgSrc: [],
    preViewImg: prefix + "5bffe320-2e9e-4a81-952c-c104ad581f00-03-xx.jpg",
  },
  {
    id: 4,
    title: "Exhibiting at the 23rd China Hi-Tech Fair",
    publish_time: "December 30,2021",
    info: `<p>The epidemic prevention and control situation was so serious this year that the GAC was postponed to the end of December, but eventually held. </p>
    <p>On December 27-29, 2021, Zhizhenhuanyu High-Tech (Shenzhen) Co., Ltd. is proud to exhibit at the 23rd China Hi-Tech Fair. (Address:Shenzhen International Convention and Exhibition Center, Baoan New Hall, Hall 11, our exhibition number:11D28-07). </p><p>The first time I came to the Shenzhen Baoan New Hall, I was attracted by the huge modern pavilion and the complete facilities.
    </p><p>In this year's Hi-Tech Fair, our products are intelligent IoT full stack solutions, including:</p><p>
    (1) Nebula series edge computing terminal, supporting multiple wireless communication methods, sensors and secure encryption computing.</p><p>(2) Nebula IoT cloud management system (including front and back-end, database)</p><p>(3) Natural language processing:OCR speech output, etc. </p>
    <p>Due to the epidemic, this year's Hi-Tech Fair was much less crowded, but we still had in-depth communication with many professional visitors and discussed in detail the future development of Smart IoT and various application scenarios. </p>`,
    imgSrc: [
      prefix + "57b011c5-0118-4407-bc84-6cb9d4e45095-03-xx.jpg",

      prefix + "80016330-59c3-4f07-b292-61661a8b6995-03-xx.jpg",
    ],
    preViewImg: prefix + "57b011c5-0118-4407-bc84-6cb9d4e45095-03-xx.jpg",
  },
  {
    id: 5,
    title: "Successful delivery of the first client's project",
    publish_time: "December 15,2021",
    info: `<p>Zhizhenhuanyu High-Tech (Shenzhen) Co., Ltd. developed a data analytics platform for one of the top environmental furniture companies in China from July 2020 to May 2021, with four phases, each of which was delivered on time and to quality. With outstanding technology, rigorous style and highly responsible spirit, the Zhizhenhuanyu team has won the client's praise. </p
    <p>The client's IT director commented: "During the project implementation process, the Zhizhenhuanyu team went deep into our specific business and was able to fully understand our needs, with clear project documentation and process control. The project schedule was strictly controlled and the quality of delivery was very high, which really ensured the smooth delivery of the project and achieved the expected project goals. After the project was delivered, it has been running for more than six months and the product is running stably and the performance meets our needs. </p><p>The Zhizhenhuanyu team has been able to provide quality technical services throughout the development process and after-sales. "
    Thank you for the gift from the customer, our company will continue to work hard, focus on research and development and go to the next level. </p>`,
    imgSrc: [],
    preViewImg: prefix + "8275396b-9ccd-4be0-a1b5-c4f29c8a7419-03-xx.jpg",
  },
  {
    id: 6,
    title: "Exhibiting at the 22nd China Hi-Tech Fair",
    publish_time: "November 16,2020",
    info: `<p>Share wisdom and ignite sparks of ideas. As China's highest level high-tech exhibition, the annual Shenzhen Hi-Tech Fair is here. </p>
    <p>November 11-15, 2020, Zhizhenhuanyu High-Tech (Shenzhen) Co., Ltd. is very honoured to exhibit at the 22nd China Hi-Tech Fair. The address of the exhibition area: Shenzhen Futian District Convention and Exhibition Center, our booth number: 10D22.</p>
    <p>In this year's fair, we exhibited our Nebula Zero edge computing terminal. 5 days of the fair, about 30 to 40 visitors visited our booth every day to discuss and exchange ideas. We have been able to get to know more people about our company, and at the same time we have felt the great potential and opportunity of the future development of intelligent Internet of Things in various industries. The exhibition was full of harvest and a complete success. </p>`,
    imgSrc: [prefix + "6fd84e76-7362-4d8c-8f42-99195051cfda-03-xx.jpg"],
    preViewImg: prefix + "6fd84e76-7362-4d8c-8f42-99195051cfda-03-xx.jpg",
  },
];
