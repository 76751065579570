const fr = {
  message: {
    hello: 'Bonjour',
    homepage:'Homepage',
    homepage_title:'Statistiques de la plateforme Star World',
    homepage_card1_title:'Nombre de projets',
    homepage_card2_title:'Nombre de passerelles',
    homepage_card3_title:'Nombre de capteurs',
    disMap_title:'Equipment Installation Statistics',    
    online_title:'Equipment Status',
    sensors_title:'Sensor Type and Quantity',

    personal_center_title:'Centre personnel',
    personal_center_subtitle1:"Changer le nom d'utilisateur",
    personal_center_subtitle2:'Changer le mot de passe',
    personal_center_subtitle3:'Configuration de la langue',

  }
}

export default fr
