<template>
  <!-- Bootstrap 的 CSS 文件 -->
  <!-- <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
    crossorigin="anonymous"
  /> -->
  <Header />
    <!-- <ul>
      <li @click="$router.push('/productAndService/app?id=0')">1</li>
      <li @click="$router.push('/productAndService/frontEnd?id=0')">2</li>

    </ul> -->
  <router-view />
  <Footer2 />
</template>


<script>
import Header from "@/components/header/";
import Footer2 from "@/components/footer/index2.vue"

export default {
  components: {
    Header,
    Footer2
  },
};
</script>
<script setup>
// var aa = process.env.VUE_APP_URL;
import { onMounted } from "vue";

onMounted(()=>{
  window.onbeforeunload = function(){
    localStorage.removeItem("currentLang");
  }
console.log("设置语言为中文");

localStorage.setItem("currentLang", "cn");

})

</script>

<style lang="less" scoped>
// @import "@/style/base";
</style>