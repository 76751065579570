import { createI18n } from "vue-i18n";
import messages from "./langs/index";

const i18n = createI18n({
  globalInjection: true,
  fallbackLocale: "cn",
  legacy: false,
  locale: "cn",
  messages,
});
export default i18n;
