import { defineStore } from "pinia";

// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id
export const useStore = defineStore("main", {
  state: () => {
    return {
      // 所有这些属性都将自动推断其类型
      currentLang: "cn",
      mapping: {
        cn: "简体中文",
        en: "English",
      },
    };
  },
  actions: {
    switchLang(lang) {
      //   alert("cjh");
      if (this.currentLang === lang) return;
      else this.currentLang = lang;
      localStorage.setItem("currentLang", lang);
    },
  },
});
