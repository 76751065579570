<template>
  <div class="logo" @click="gotoHome">
    <img :src="prefix + img"/>
    <div>智臻寰宇</div>
  </div>
</template>

<script>
 import { prefix } from '@/until/newsdata';

export default {
  name: "logo",
  data(){
    return {
      prefix,
      img:'e863c324-fcc3-4e6e-8f66-2e774ed56c4a-03-xx.jpg'
    }
  },
  methods:{
    gotoHome(){
      this.$router.push('/')
    }
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: 'youshe';
  src: url('@/assets/fonts/FangZhengKaiTiJianTi-1.ttf');
}
.logo{
  cursor: pointer;
  display: flex;
  align-items: center;
  div{
    font-size: 1rem;
    margin-left: 10px;
    // font-weight: bold;
    line-height: 40px;
    font-family: 'youshe' !important;
  }
}
img {
  // -webkit-filter: invert(100%);
  // filter: invert(100%);
  width: 3.5rem;
  height: 3.5rem;
}
</style>