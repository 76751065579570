<template>
 <div class="navMenu">

    <div class="menu-item" v-for="(item,index) in menuItemData" :key="index">
        <span class="catagory ">{{$t(item.title)}}</span>
        <hr/>
        <div v-for="(item2,key) in menuItemData[index].child" :key="key">
            <span class="subcatagory" @click="$router.push(item2.to)">{{$t(item2.title)}}</span>
            <ul>
                
                    <li v-for="(item3,index2) in item2.child" :key="index2" @click="$router.push(item3.to)">
                        {{$t(item3.title)}}
                    </li>
                    <!-- <li @click="$router.push('/productAndService/app?id=0')">1</li>
                    <li @click="$router.push('/productAndService/frontEnd?id=0')">2</li> -->
                
            </ul>
        </div>
    </div>
 </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
    name:'navMenu',
    props:{
        menuItemData:{
            type:Array,
            require:true
        }
    },
    setup(){
        const router = useRouter()
        
        function goTo(path){
            // alert("fbgh")
            console.log(path);
            router.push(path)
        }
        return {
            router,goTo
        }
    }
}
</script>

<style lang="less" scoped>
.subcatagory{
        cursor: pointer;
        font-size: 14px;
        line-height: 30px;
        color: #333;
        display: inline-block;
        // padding-left: 32px;
        &:hover{
                color: rgb(177, 41, 231);
            }
    }
.catagory{
    color: #333;
    font-size: 16px;
    // padding-bottom: 5px;
    // border-bottom: 1px solid #dadada;
    display: inline-block;
    
}
.navMenu{
    position: absolute;
    top: 87px;
    // left: 0;
    padding: 1rem;
    // width: 100vw;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: start;
    z-index: 999;
    line-height: 1.2rem !important;
    .menu-item{
        margin: 0 1rem;
    }
    ul{
        list-style: none;
        li{
            color:#777;
            margin: 2px 0;
            font-size: 14px;
            line-height: 22px;
            padding-left: 32px;
            cursor: pointer;
            &:hover{
                color: rgb(177, 41, 231);
            }
        }
    }
    transition: 0.3 ease;
}
</style>