<template>
  <!-- 导航抽屉 -->
  <el-menu>
    <el-menu-item index="0" @click="goTo('/')">
      <span style="font-size: 18px">{{ $t("message.home") }}</span>
    </el-menu-item>
    <el-sub-menu
      v-for="(item, index) in routerFilter"
      :key="index"
      :index="item.meta.title"
    >
      <template #title>
        <span style="font-size: 18px">{{ $t(item.meta.title) }}</span>
      </template>

      <template v-if="item.meta.hasMenu && item.name === 'productAndService'">
        <el-menu-item-group :title="$t('message.productTitle2')">
          <el-menu-item
            :index="`${index}-1`"
            @click="goTo('/productAndService/hardware')"
            >{{ $t("navMenu.product.child1.title") }}</el-menu-item
          >
          <!-- 机器视觉 -->
          <el-menu-item
            :index="`${index}-2`"
            @click="goTo('/productAndService/vision')"
            >{{ $t("navMenu.product.child4.title") }}</el-menu-item
          >
          <el-menu-item
            :index="`${index}-3`"
            @click="goTo('/productAndService/software')"
            >{{ $t("navMenu.product.child2.title") }}</el-menu-item
          >
          <el-menu-item
            :index="`${index}-4`"
            @click="goTo('/productAndService/digtaltwins')"
            >{{ $t("navMenu.product.child3.title") }}</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group :title="$t('message.productTitle1')">
          <el-menu-item
            :index="`${index}-5`"
            @click="goTo('/productAndService/electronic')"
            >{{ $t("navMenu.serive.child1.title") }}</el-menu-item
          >
          <el-menu-item
            :index="`${index}-6`"
            @click="goTo('/productAndService/app')"
            >{{ $t("navMenu.serive.child2.title") }}</el-menu-item
          >
          <el-menu-item
            :index="`${index}-7`"
            @click="goTo('/productAndService/frontEnd')"
            >{{ $t("navMenu.serive.child3.title") }}</el-menu-item
          >
          <el-menu-item
            :index="`${index}-8`"
            @click="goTo('/productAndService/dataAnla')"
            >{{ $t("navMenu.serive.child4.title") }}</el-menu-item
          >
          <!-- <el-menu-item
            :index="`${index}-9`"
            @click="goTo('/productAndService/gateWay')"
            >{{ $t("navMenu.serive.child5.title") }}</el-menu-item
          > -->
        </el-menu-item-group>
      </template>

      <template v-if="item.meta.hasMenu && item.name === 'support'">
        <el-menu-item-group :title="$t('support.table.download')">
          <el-menu-item :index="`${index}-9`" @click="goTo('/support')">{{
            $t("support.title2")
          }}</el-menu-item>
        </el-menu-item-group>
      </template>
    </el-sub-menu>

    <el-menu-item
      :index="index2 + 1"
      v-for="(item2, index2) in routerFilter2"
      :key="index2"
      @click="goTo(item2.path)"
    >
      <span style="font-size: 18px">{{ $t(item2.meta.title) }}</span>
    </el-menu-item>
    <!-- 语言切换 -->
    <el-sub-menu index="8">
      <template #title>
        <span style="font-size: 18px">{{ currentLang }}</span>
      </template>

      <el-menu-item-group>
        <el-menu-item index="9" @click="switchLang('cn')"
          >简体中文</el-menu-item
        >
        <el-menu-item index="10" @click="switchLang('en')"
          >English</el-menu-item
        >
      </el-menu-item-group>
    </el-sub-menu>
  </el-menu>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, computed, watch } from "vue";
import { useStore } from "@/store";

import { useI18n } from "vue-i18n";
import { Watch } from "@element-plus/icons-vue";

const { locale } = useI18n(); //这个i18n不能导入到pinia中用
const store = useStore();

let currentLang = ref("简体中文");

const switchLang = (lang) => {
  store.switchLang(lang);
  locale.value = store.currentLang;
  if (store.currentLang === "cn") {
    currentLang.value = "简体中文";
    // router.push("/");
  }
  if (store.currentLang === "en") {
    currentLang.value = "English";
    // router.push("/en");
  }

  emit("clickItem", false);
};

const router = useRouter();
const routeList = router.options.routes;
// const showMenu = ref(false)
const emit = defineEmits(["clickItem"]);

const routerFilter = computed(() => {
  return routeList.filter((obj) => {
    return obj.meta.hasMenu == true;
  });
});

const routerFilter2 = computed(() => {
  return routeList.filter((obj) => {
    return (
      obj.meta.hasMenu == false &&
      obj.meta.showInNav == true &&
      obj.name !== "home"
    );
  });
});

const goTo = (path) => {
  emit("clickItem", false);
  router.push(path);
};
</script>

<style lang="less" scoped>
.el-menu-item-group__title {
  font-size: 16px !important;
}

.el-menu {
  border-right: none !important;
}
.drawer-link {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1rem;
  div {
    padding: 0px;
    margin: 15px 0;
  }
  .sub1 {
    margin-left: 15px;
    font-size: 0.8rem;
  }
  .sub2 {
    margin-left: 30px;
    font-size: 0.8rem;
    color: #ccc;
  }
}
</style>
