<template>
    <nav class="nav">
      <div class="links">
        <!-- 遍历路由配置项来生产导航   -->
        <div class="links-item" v-for="(obj,index) in routerFilter" :key="index" 
        @mouseenter="toShowMenu(obj,index)" @mouseleave="currentActive=null">
        <router-link :to="obj.path">
          {{ $t(obj.meta.title) }}
        </router-link>

          <!-- 导航菜单 -->
          <navMenu v-if="obj.meta.hasMenu" v-show="currentActive===index" @mouseleave="currentActive=null"
          :menuItemData="obj.meta.menu"
          />
        </div>
  
        <!-- 语言切换 -->
        <!-- <langSwitch/> -->
  
        <!-- 搜索图标 -->
        <!-- <el-popover placement="bottom" title="搜索" width="auto" trigger="click">
          <template #reference>
            <div class="icon" @click="showSearch">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="white"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </div>
          </template>
          <el-input
            v-model="searchValue"
            class="w-100"
            size="large"
            placeholder="输入搜索内容"
            clearable
            :autofocus="true"
            @keyup.enter="search()"
          >
            <template #append>
              <el-button :icon="Search" @click="search()" />
            </template>
          </el-input>
        </el-popover> -->
      
        
        
      </div>
      <!-- 登录图标 -->
      <!-- <div class="icon" @click="router.push('/login')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="white"
          class="bi bi-person-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
          />
        </svg>
      </div> -->
  
     
    </nav>
     <!-- 菜单按钮 -->
     <div class="burger" @click="drawer = true">
        <div class="burger-line1"></div>
        <div class="burger-line2"></div>
        <div class="burger-line3"></div>
        <!-- pppppp -->
      </div>
    <!-- 导航抽屉 -->
    <el-drawer
      v-model="drawer"
      :title="$t('message.navigation')"
      direction="rtl"
      size="60%"
    >
   <sideNav @clickItem="onSideNavClick"/>

    </el-drawer>
  
  </template>
  
  <script>
  export default {
    name: "navs",
  };
  </script>
  
  <script setup>
  import sideNav from "./components/sideNav.vue"

  import { useRouter } from "vue-router";
  import { ref, computed } from "vue";
  import navMenu from "@/components/navMenu/index.vue"
 
  
  const router = useRouter();
  const routeList = router.options.routes;
  // const showMenu = ref(false)
  
  var drawer = ref(false);
  
  const routerFilter = computed(() => {
    return routeList.filter((obj) => {
      return obj.meta.showInNav == true;
    });
  });
  
  var searchValue = ref("");
  
  function search() {
    drawer.value = false;
    router.push({
      path: "/search",
      query: {
        kw: searchValue.value,
      },
    });
  }
  
  

  let currentActive = ref(null)

  function toShowMenu(route,index){
    if(route.meta.hasMenu)
      // showMenu.value=true
      currentActive.value = index
  }


  function onSideNavClick(isClick){
    drawer.value=isClick
  }
  </script>
  
  <style lang="less" scoped>
  .drawer-link {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1rem;
    div {
      padding: 0px;
      margin: 15px 0;
    }
    .sub1{
      margin-left: 15px;
      font-size: 0.8rem;
    }
    .sub2{
      margin-left: 30px;
      font-size: 0.8rem;
      color: #ccc;
    }
  }
 
  
  .nav{
    // position: absolute;
    // top: 50%;
    // right: 1.5rem;
    // transform: translateY(-50%);
    height:3.5rem;
    margin: 0 auto;
    font-size: 16px;
    line-height: 22px;

  }
//   nav,
  .links {
    display: flex;
    align-items: center;
    // justify-self: end;
    height: 100%;
    justify-content: center;
    z-index: 999;
    .links-item{
        height: inherit;
        display: inherit;
        align-items: center;
    }
    .burger {
      display: none;
    }
  
    a {
      color: black;
      text-decoration: none;
      margin: 0 24px;
      border-bottom: 4px;
      // font-size: 0.8rem;
      // transition: border-bottom 0.5s;
      &:hover {
        border-bottom: 4px rgb(108, 49, 218) solid;
      }
    }
  
  }
  
  // 搜索图标
  .icon {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    // width: 22px;
    // height: 22px;
    // background-color: yellow;
    color: var(--text-color-lightest);
    // background:url(~@/assets/search.png) no-repeat scroll center center;
    margin: 0 24px;
  }
  
  @media screen and (max-width: 1200px) {
    nav {
      .links {
        display: none;
      }
  
    }
    .burger {
        display: inline-block;
        position: relative;
        width: 20px;
        // height: 6px;
        justify-self: end;
        cursor: pointer;
        right: 1.5rem;
        // background-color: green;
      }
  
      .burger-line1,
      .burger-line2,
      .burger-line3 {
        width: 20px;
        height: 2px;
        background-color: black;
      }
  
      .burger-line1 {
        position: absolute;
        top: -6px;
      }
  
      .burger-line3 {
        position: absolute;
        top: 6px;
      }
    
  }
  </style>
  