<template>
  <div class="top-nav hidden-md-and-down">
    <div @click="open" class="nav">
      <div>
        {{ store.mapping[store.currentLang] }}
      </div>
    </div>

    <div class="transition" :style="styleobj">
      <h3 class="title" v-if="isOpen">{{ $t("message.switchLang") }}</h3>
      <ul v-if="isOpen">
        <li @click="switchLang('cn')">
          <span class="active" v-if="store.currentLang === 'cn'"> > </span>
          简体中文
        </li>
        <li @click="switchLang('en')">
          <span class="active" v-if="store.currentLang === 'en'"> > </span>
          English
        </li>
      </ul>
    </div>
  </div>

  <header class="header">
    <!-- ｌｏｇｏ -->
    <logo />
    <!-- 导航 -->
    <navs />
    <!-- <navHover/> -->
  </header>
</template>

<script setup>
import { ref, reactive } from "vue";
import logo from "@/components/logo/";
import navs from "@/components/nav/index2.vue";
let isOpen = ref(false);
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const { locale } = useI18n(); //这个i18n不能导入到pinia中用
const store = useStore();

const router = useRouter();

let styleobj = reactive({
  // background: 'yellow',
  // height: '0px',
  // width: '100px',
  // display:'none',

  height: "0",
  width: "100%",
  background: "#f7f7f7",
  // transition: height 5s;
  color: "black",
});

const switchLang = (lang) => {
  // if (lang === "en") {
  //   router.push("/en");
  // }
  // if (lang === "cn") {
  //   router.push("/");
  // }
  store.switchLang(lang);
  locale.value = store.currentLang;
  isOpen.value = false;
  styleobj.height = "0px";
  styleobj.padding = "0";
};

const open = () => {
  if (!isOpen.value) {
    // styleobj.padding = '40px'
    styleobj.height = "80px";
  } else {
    styleobj.height = "0px";
    styleobj.padding = "0";
  }
  isOpen.value = !isOpen.value;
  // styleobj.display = 'block'
};
</script>

<style lang="less" scoped>
.transition {
  // background-color: yellow;
  // height: 20px;
  // width: 100px;
  // display:none;
  transition: height 0.8s;
  // transition: display 1s;

  // transition: padding 1s;
  // box-sizing: content-box;

  // &:hover{
  //   height: 100px;
  // }
}

// @import "@/style/base";
.top-nav {
  height: auto;
  width: 100%;
  background-color: black;
  color: rgb(177, 177, 177);
  // background-color: aqua;
  font-size: 12px;
  overflow: hidden;
  position: relative;
  .nav {
    display: flex;
    width: 90%;
    margin: 0 auto;
    text-align: right;
    justify-content: end;
    div {
      cursor: pointer;
      height: 100%;
      line-height: 25px;
      padding: 0 8px;
      &:hover {
        background-color: #f7f7f7;
      }
    }
  }
  .content {
    height: 0;
    width: 100%;
    background-color: #f7f7f7;
    // transition: height 5s;
    padding: 1.5rem;
    color: black;
  }
  .title {
    font-size: 0.9rem;
    margin-bottom: 20px;
    // margin-top: 40px;
    // margin-left: 40px;
    position: absolute;
    top: 40px;
    left: 40px;
  }
  ul {
    font-size: 0.7rem;
    position: absolute;
    top: 65px;
    left: 40px;
    li {
      display: inline-block;
      width: max-content;
      cursor: pointer;
      margin: 10px 0;
      margin-right: 100px;

      color: #808080;
      &:hover {
        color: #6c31da;
      }
    }
  }
}
// .active{
//   padding-bottom:3px ;
//   border-bottom: #6c31da 2px solid;
// }
.active {
  color: #6c31da;
  font-weight: bolder;
  margin-right: 8px;
}
/* 栅格布局 */
.header {
  // width: 100vw;
  // height: 8vh;
  // display: grid;
  padding: 0 1.5rem;
  // background-color: var(--backdrop-color);
  // background-color: rgba(0, 0, 0, 0.2);
  // grid-template-columns: 1fr 2fr;
  // align-content: center;
  // position: relative;
  // z-index: 200;
  border-bottom: 2px solid #e6e6e6;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  position: relative;
}
</style>
